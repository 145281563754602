import React, { useEffect, useState } from "react"
import { addMiddleware, resetMiddlewares } from "redux-dynamic-middlewares"
import { createCableCarRoute } from "redux-cablecar"
import { CableContext, cableContextInitialState } from "./CableContext"
import { getUserSession } from "../../selectors/user"
import { useSelector } from "react-redux"

const CableProvider: React.FC = (props) => {
  const session = useSelector(getUserSession)
  const apiToken = session.apiToken
  const [cableContext, setCableContext] = useState(cableContextInitialState)

  const addCableMiddleware = (): void => {
    const cableCarRoute = createCableCarRoute({
      webSocketURL: `${process.env.ACTION_CABLE_URL}?token=${apiToken}`
    })
    resetMiddlewares()
    const cableCarMiddleware = cableCarRoute.createMiddleware()
    addMiddleware(cableCarMiddleware)
    setCableContext({
      apiToken: apiToken,
      cableCarRoute: cableCarRoute
    })
  }

  useEffect(() => {
    if (!apiToken) return
    addCableMiddleware()
  }, [apiToken])

  return (
    <CableContext.Provider value={cableContext}>
      {props.children}
    </CableContext.Provider>
  )
}

export default CableProvider
