import ReactDOM from "react-dom/client"
import App from "./assets/js/app/App"
import { store } from "./assets/js/app/state/store"
import { Provider } from "react-redux"
import React from "react"
import { QueryStringContextProvider } from "./assets/js/shared/contexts/QueryStringContextProvider"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryStringContextProvider>
        <App />
      </QueryStringContextProvider>
    </Provider>
  </React.StrictMode>
)
