import dotenv from "dotenv"
import React, { Suspense, useContext, useEffect, useState } from "react"
import { Router } from "react-router-dom"
import Modal from "react-modal"
import i18n from "./i18n"
import { persistor } from "./state/store"
import history from "./routes/history"
import { Routes } from "./routes/Routes"
import { PersistGate } from "redux-persist/integration/react"
import UserMessage from "../features/userMessage/components/UserMessage"
import LoadingSpinner from "../shared/components/ui/LoadingSpinner"
import CableConnection from "../shared/components/cable/CableConnection"
import { getCurrentUser, getCurrentUserId } from "../shared/selectors/user"
import { userCablePrefix, UserChannelName } from "../features/user/state/userTypes"

import "@fontsource/open-sans/300.css"
import "@fontsource/open-sans"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import { fetchCurrentUserAction } from "../features/user/state/userActions"
import { sharedCablePrefix, SharedChannelName } from "../shared/state/sharedTypes"
import CableProvider from "../shared/components/cable/CableProvider"
import { chatCablePrefix, ChatChannelName } from "../features/chat/state/chatTypes"
import { ErrorBoundary } from "./ErrorBoundary"
import Button from "../shared/components/buttons/Button"
import Apm from "./apm"
import { useAppDispatch, useAppSelector } from "../shared/hooks"
import { CookiesProvider } from "react-cookie"
import { QueryStringContext } from "../shared/contexts/QueryStringContextProvider"

dotenv.config()

i18n

Apm

const Loading = (): JSX.Element => {
  return <LoadingSpinner />
}

const genericErrorMessage = (
  <div className="text-xl space-y-4 flex flex-col justify-center items-center h-screen">
    <h1>Something went wrong. Please try reloading the page</h1>
    <p>If the error persists please go to the home page</p>
    <Button href="/">Home Page</Button>
  </div>
)

const App: React.FC = () => {
  Modal.setAppElement("#root")

  const [connectionRefresherIndex, setConnectionRefresherIndex] = useState(null)
  const currentUser = useAppSelector(getCurrentUser)
  const currentUserId = useAppSelector(getCurrentUserId)
  const dispatch = useAppDispatch()
  const fetchCurrentUser = () => dispatch(fetchCurrentUserAction())
  const { tenantId: queryTenantId } = useContext(QueryStringContext)

  useEffect(() => {
    if(queryTenantId) return

    if (currentUser) {
      setConnectionRefresherIndex(currentUser.currentTenantId)
    }
    if (!currentUserId || currentUser) return
    fetchCurrentUser()
  }, [currentUser, currentUserId])

  // we should really check that this prop is false, as it could also be
  // undefined, in which case we should render as a precaution
  if (currentUser && currentUser.activeInCurrentTenant == false) { return <h1>Your account is inactive</h1> }

  const cableConnections = () => {
    if (!currentUser) return null

    return (<>
      <CableConnection
        channelName={UserChannelName}
        actionPrefix={userCablePrefix}
        key={`${connectionRefresherIndex}-user`}
      />
      <CableConnection
        key={`${connectionRefresherIndex}-shared`}
        channelName={SharedChannelName}
        actionPrefix={sharedCablePrefix}
      />
      <CableConnection
        channelName={ChatChannelName}
        actionPrefix={chatCablePrefix}
        tenantId={connectionRefresherIndex}
        key={`${connectionRefresherIndex}-chat`}
      />
    </>)
  }

  return (
    <ErrorBoundary errorMessage={genericErrorMessage} >
      <PersistGate persistor={persistor}>
        <CableProvider>
          {cableConnections()}
          <Suspense fallback={<Loading />}>
            <CookiesProvider>
              <Router history={history}>
                <Routes />
              </Router>
              <UserMessage />
            </CookiesProvider>
          </Suspense>
        </CableProvider>
      </PersistGate>
    </ErrorBoundary >
  )
}

export default App
