import React, { useEffect } from "react"
import classNames from "classnames"
import { IUserMessageState, UserMessageTypes } from "../state/userMessageTypes"
import { markUserMessageRead } from "../state/userMessageActions"
import { useTranslation } from "react-i18next"
import { getUserMessage } from "../../../shared/selectors/userMessage"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

const UserMessage: React.FC = (): JSX.Element => {
  const { t } = useTranslation(["userMessage"])
  const dispatch = useDispatch()
  const userMessage: IUserMessageState = useSelector(getUserMessage)

  const messageKey = userMessage?.messageKey
  const messageArgs = userMessage?.messageArgs
  const type = userMessage?.type
  const isRead = userMessage?.isRead
  const onMarkUserMessageRead = () => dispatch(markUserMessageRead())

  useEffect(() => {
    const timer = setTimeout(() => onMarkUserMessageRead(), 4000)
    return () => clearTimeout(timer)
  }, [messageKey, messageArgs])

  const renderMessage = (): JSX.Element => {
    if (!messageKey) return null
    return (
      <div
        className={
          classNames("flex-1", "text-white", "font-bold", "p-2", "text-center", "mb-2", "mx-5", {
            "bg-yellow-400": type == UserMessageTypes.MESSAGE,
            "bg-green-400": type == UserMessageTypes.SUCCESS,
            "bg-red-400": type == UserMessageTypes.ERROR,
          }
          )}
        onClick={onMarkUserMessageRead}
      >
        {t(`userMessage:${messageKey}`, messageArgs)}
      </div>
    )
  }

  return (
    <div
      className={classNames("flex", "absolute", "bottom-0", "items-center", "w-full", "z-30", {
        "hidden": isRead
      })}>
      {renderMessage()}
    </div>
  )
}

export default UserMessage
